import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormControl, OutlinedInput, InputAdornment, Button } from '@material-ui/core';
import { Icon, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    minHeight: 400,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchInputContainer: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.11)',
    borderRadius: theme.spacing(1),
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: theme.spacing(2),
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  searchButton: {
    maxHeight: 45,
    minWidth: 135,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
}));

const Hero = props => {
  const data = useStaticQuery(graphql`
    query {
      pages: allContentfulPages {
        edges {
          node {
            title
            slug
          }
        }
      }
      header: allContentfulHeader(filter: {id: {eq: "0874841e-eb18-55ca-8544-d0b518de4df7"}}) {
          edges {
            node {
              title
              subTitle
              slug
              image {
                fluid(maxHeight: 600, maxWidth: 1400) {
                  src
                }
              }
              firstButton
              secondButton
            }
          }
        }
      }
  `)
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {data.header.edges.map((edge) => {
        const info = edge.node;
        return (
          <header>
              <Image
                src={info.image.fluid.src}
                srcSet={info.image.fluid.src}
                // srcSet="https://assets.maccarianagency.com/the-front/photos/about/hero-image.png 2x"
                alt="About"
                className={classes.image}
                lazyProps={{
                  width: '100%',
                  height: '100%',
                }}
              />
              <Section className={classes.section}>
                <SectionHeader
                  title={info.title}
                  subtitle={info.subTitle}
                  align="left"
                  data-aos="fade-up"
                  titleProps={{
                    className: clsx(classes.title, classes.textWhite),
                    variant: 'h3',
                  }}
                  subtitleProps={{
                    className: classes.textWhite,
                  }}
                />
                <div className={classes.searchInputContainer} data-aos="fade-up">
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      size="large"
                      startAdornment={
                        <InputAdornment position="start">
                          <Icon
                            fontIconClass="fas fa-search"
                            fontIconColor={theme.palette.primary.dark}
                          />
                        </InputAdornment>
                      }
                      placeholder="Search for the question"
                    />
                  </FormControl>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    className={classes.searchButton}
                  >
                    Search
                  </Button>
                </div>
              </Section>
          </header>
        )
      }) }
      
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
